@import '../../../assets/styles/variables';

.app-regions-dropdown {
  .app-select {
    width: 279px;

    .app-select-trigger-container {
      width: 100%;
      background: white;
      border-radius: 12px;
      padding: 15.5px 15px;
      box-sizing: border-box;
    }

    &.app-popup-menu {
      .app-select-items {
        width: 100%;

        .app-select-item {
          padding: 12px 15px;
        }
      }
    }
  }

  .app-region-dropdown-checkbox {
    background: #00000029;
    width: 16px;
    height: 16px;
  }
}

.app-analytics-widget-big-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-right: 1px solid $primary-gray-color;
  box-sizing: border-box;
  padding: 0 10px;
  flex: 1;

  &.app-no-border-right {
    border-right: none;
  }
}

.app-analytics-widget-big-icon {
  display: flex;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.app-analytics-widget-systems-big-icon {
  border: 10px solid $secondary-blue-color;
}

.app-analytics-widget-companies-big-icon {
  border: 6px solid $primary-green-color;

 .app-analytics-big-icon-count {
    margin-top: -10px;
  }
}

.app-analytics-delta-count {
  font-weight: 600;
  font-size: 19px;
  line-height: 21px;
  margin-bottom: 5px;
  color: black;

  svg {
    width: 24px;
    height: 15px;
    margin-right: 5px;
  }
}

.app-analytics-widget-calls-big-icon {
  border: 13px solid #F85E00;

  &.app-analytics-widget-calls-big-icon-green {
    border: 13px solid $primary-green-color;
  }

  width: 180px;
  height: 180px;
  margin-bottom: 14px;

  .app-analytics-big-icon {
    gap: 7px;
  }

  .app-analytics-big-icon .app-analytics-big-icon-count {
    color: $primary-green-color;
    font-size: 26px;
    font-weight: 600;
  }

  .app-analytics-big-icon .app-analytics-big-icon-title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.app-analytics-big-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  .app-analytics-big-icon-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-analytics-big-icon-count {
    font-size: 40px;
    font-weight: 600;
  }

  .app-analytics-big-icon-title {
    font-size: 16px;
    text-transform: uppercase;
    max-width: 150px;
    text-wrap: pretty;
    text-align: center;
  }
}

.app-analytics-widget-statistic-icons {
  display: flex;
  justify-content: space-around;
  gap: 20px;

  .app-analytics-widget-statistic-small-icon {
    display: flex;
    align-items: center;
    gap: 10px;

    .app-analytics-widget-statistic-icons-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .app-analytics-widget-statistic-small-icon-count {
        font-weight: 600;
        font-size: 20px;
        color: $primary-black-color;
      }

      .app-analytics-widget-statistic-small-icon-title {
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }
}


.app-analytics-widgets {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  .app-analytics-widget {
    display: flex;
    flex: 1;
    padding: 15px 15px 25px 28px;
    gap: 10px;

    .app-home-widget-left,
    .app-home-widget-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      gap: 15px;
    }

    .app-home-widget-right {
      justify-content: center;
      gap: 35px;
    }

    .app-home-widget-title-text {
      align-self: flex-start;
      font-weight: 600;
      font-size: 20px;
      text-transform: uppercase;
    }


    .app-analytics-widget-body-small-icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
      align-items: flex-end;
      padding: 0 10px;

      .app-analytics-small-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;

        .app-analytics-small-icon-icon {
          display: flex;
          max-width: 36px;
          max-height: 40px;

          img {
            display: flex;
            width: 100%;
          }
        }

        .app-analytics-small-icon-count {
          font-weight: 600;
          color: $primary-green-color;
          font-size: 26px;
        }

        .app-analytics-small-icon-title {
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }

    .app-analytics-widget-footer {
      display: flex;
      align-items: center;
      justify-content: center;

      .app-percent-growth {
        display: flex;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        align-items: center;
        gap: 5px;
      }
    }
  }

}

.app-analytics-table {
  .app-table-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .app-table-header-row,
  .app-table-content-row {
    display: grid;
    grid-template-columns: 10% 70% 20%;
  }

  .app-table-col-server {
    font-weight: 600;
    font-size: 16px;
  }

  .app-table-col-location {
    font-size: 12px;
  }

  .app-table-col-server-status {
    font-size: 16px;
    font-weight: 600;

    &.app-table-col-server-status-live {
      color: $primary-green-color;
    }

    &.app-table-col-server-status-down {
      color: $secondary-red-color
    }
  }
}
