@import 'variables';

.app-help-link {
  color: #3A7FF5;
}

.app-mp-bold-16 {
  @include bold16;
  letter-spacing: 0;
}

.app-flex-vertical-scrollable {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
}

.app-form-section-title {
  @include semi24;
  letter-spacing: 0;
  color: $primary-black-color;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.app-content-toolbar {
  box-shadow: 0 1px 1px $border-color;
  height: $toolbarHeight;
  padding-left: 30px;
  padding-right: 0;
}

.app-content {
  position: relative;
  background-color: $primary-gray-color;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 30px;
  overflow: auto;

  &.app-transfer-site-page {
    padding: 42px 64px;
    overflow: hidden;
  }

  .app-content-footer {
    display: flex;
    flex-direction: column;
    align-items: end;

    .app-table-total {
      width: 177px;
      font: normal normal 600 20px/28px 'myriad-pro';
      color: $primary-black-color;
      text-align: right;
      text-transform: uppercase;
      white-space: nowrap;

      &:first-child {
        margin-top: 14px;
      }

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }
}

.app-toolbar-breadcrumps {
  display: flex;
  align-items: center;

  span {
    margin-right: 9px;
  }

  .app-toolbar-breadcrump {
    @include bold26;
    letter-spacing: 0;
    color: $primary-black-color;
    text-transform: uppercase;
    display: flex;
  }

  .app-toolbar-breadcrump-separator {
    @include bold26;
    color: $primary-green-color;
    height: 32px;

    &.app-rotate-svg-180 svg {
      transform: rotate(180deg);
      transform-origin: center;
    }
  }
}


.app-search {
  position: relative;
  font: normal normal normal 20px/28px 'myriad-pro';
  color: $primary-black-color;
  max-width: 737px;
  width: 50%;
  display: flex;
  align-items: center;

  .app-search-search-icon {
    position: absolute;
    top: 9px;
    left: 12px;
  }

  .app-search-clear-icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  input {
    border-radius: 12px;
    border: none;
    padding: 12px 12px 12px 46px;
    background-color: $primary-white-color;
    box-sizing: border-box;
    width: 100%;
    @include formControlFont;
    color: $primary-black-color;
    height: 44px;

    &::placeholder {
      @include formControlFont;
      color: $primary-black-color;
      opacity: 0.13;
    }

    &:focus {
      outline: none;
    }
  }
}

@mixin buttonShared {
  font: normal normal 600 20px/20px 'myriad-pro';
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  padding: 12px 14px;
  min-width: 194px;
  border-radius: 12px;
  text-align: center;

  &[disabled] {
    cursor: default;
    color: #B0B0B0;
    background-color: hexToRGBA(#b0b0b0, 0.23);
    border: 1px solid hexToRGBA(#b0b0b0, 0);

    &.app-primary-button.app-button-disabled-special {
      color: hexToRGBA($primary-white-color, 0.6);
      background-color: #929292;
    }

    &.app-secondary-button.app-button-disabled-special {
      color: hexToRGBA(#929292, 0.6);
      background-color: hexToRGBA(#F1F1F1, 0.6);
      border: 1px solid hexToRGBA(#929292, 0.6);
      box-sizing: border-box;
    }
  }

  &[disabled].app-disabled-white-text {
    color: $primary-white-color;
  }
}

.app-secondary-button {
  @include buttonShared;
  border: $primary-green-color 1px solid;
  box-sizing: border-box;
  background: transparent;
  color: $primary-green-color;
}

.app-primary-button {
  @include buttonShared;
  border: none;
  background-color: $primary-green-color;
  color: $primary-white-color;
}

.app-warn-button {
  @include buttonShared;
  border: none;
  background-color: $secondary-red-color;
  color: $primary-white-color;
}

.app-table {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;

  &[aria-disabled="true"],
  &[aria-disabled],
  *[aria-disabled="true"],
  *[aria-disabled] {
    opacity: 0.5;
  }

  &[aria-disabled="false"],
  *[aria-disabled="false"] {
    opacity: 1;
  }

  .app-table-4-cols-with-icon {
    display: grid;
    grid-template-columns: 1fr 250px 180px 80px
  }

  .app-table-3-cols {
    display: grid;
    grid-template-columns: 1fr 173px 180px
  }
  
  .app-table-2-cols-with-icon {
    display: grid;
    grid-template-columns: 1fr 1fr 17px;
  }

  .app-table-3-cols-with-icon {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 50px;
  }

  .app-table-4-cols {
    display: grid;
    grid-template-columns: repeat(3, 1fr)  50px;
  }

  .app-table-5-cols {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .app-table-6-cols {
    display: grid;
    grid-template-columns: repeat(5, 1fr)  50px;
  }

  .app-table-col-user-status {
    display: flex;
    align-items: center;

    .app-table-col-user-status-text {
      width: 66px;
    }

    .app-table-col-user-status-icon {
      height: 20px;
      display: flex;
      align-items: end;
    }
  }

  .app-table-header-row {
    font: normal normal 600 20px/28px 'myriad-pro';
    color: $primary-black-color;
    padding: 0 30px;

    .app-table-header-row-with-sort {
      display: flex;
      align-items: center;
      gap: 10px;

      &.app-table-bulk-delete {
        .app-select {
          .app-select-items {
            left: -100px;
          }
        }
      }

      .app-select {
        width: fit-content;
        transform: translateY(1px);

        .app-select-items {
          width: fit-content;
          top: 25px;
          left: -26px;
          white-space: nowrap;

          .app-select-item {
            padding: 12px 20px;
            font: normal normal normal 16px/22px 'myriad-pro';
            text-transform: none;

            &[aria-selected="true"] .app-table-col-green-icon {
              circle {
                fill: $primary-white-color;
              }
            }
          }
        }
      }
    }

    &.app-table-scroll-visible {
      margin-right: 15px;
      padding-right: 40px;
    }
  }

  &.app-table-content, .app-table-content {
    &.app-table-scroll-visible {
      .app-table-content-row {
        margin-right: 15px;
      }
    }
  }

  .app-table-bulk-delete-totals {
    font: normal normal 600 32px/32px 'myriad-pro';
    text-transform: uppercase;
  }

  .app-table-content-row {
    background-color: $primary-white-color;
    border-radius: 12px;
    font: normal normal normal 20px/28px 'myriad-pro';
    padding: 15px 30px;

    .app-table-action-icon {
      &:hover {
        cursor: pointer;
        filter: $img-primary-white-filter;
      }
    }

    .app-table-bulk-delete-icon {
      height: 34px;

      &:hover {
        cursor: pointer;
      }
    }

    &:not([data-hover-disabled="true"]) {
      cursor: pointer;

      &:hover {
        background-color: $primary-green-color;
        color: white;

        .app-payment-overview-status-text{
          color: white !important;
        }

        .app-table-action-icon {
          filter: $img-primary-white-filter;
        }

        .app-table-col-green-icon {
          circle {
            fill: $primary-white-color;
          }
        }
      }
    }
  }
}

.app-navigation-link {
  border-radius: 12px;
  color: $primary-black-color;
  text-decoration: none;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 14px;

  img {
    margin-right: 6px;
    width: 19px;
  }

  &:not(.app-navigation-link-active):hover {
    @include link-hover;

    img {
      filter: $img-primary-green-filter
    }
  }

  &.app-navigation-link-active {
    @include link-active;

    img {
      filter: $img-primary-white-filter
    }
  }
}

.app-tabs {
  background-color: $primary-black-color;
  display: flex;
  align-items: center;

  .app-tab {
    font: normal normal normal 16px/22px 'myriad-pro';
    letter-spacing: 0;
    color: $primary-white-color;
    text-decoration: none;
    opacity: 1;
    text-align: center;
    width: 150px;
    padding: 12px 0;

    &.app-tab-active {
      font: normal normal 600 18px/26px 'myriad-pro';
      border-bottom: 3px solid $primary-green-color;
      box-sizing: border-box;
    }
  }
}

.app-popup-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(136, 136, 136, 0.6);
}

.app-popup-content {
  background: white url('../Popup-background.svg') center center no-repeat;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 24px;
  width: 55%;
  height: 500px;
  max-width: 927px;

  .app-popup-header {
    padding: 25px 35px;
    font: normal normal 600 20px/20px 'myriad-pro';
    display: flex;
    align-items: center;
    border-bottom: 1px $popup-header-border solid;

    .app-popup-title {
      flex-grow: 1;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0;
      color: $primary-black-color;
    }
  }

  .app-popup-info {
    padding: 52px 0 37px 0;
    text-align: center;
    font: normal normal normal 20px/20px 'myriad-pro';
    letter-spacing: 0;
    color: $primary-black-color;
    opacity: 1;

    &.app-px-31 {
      padding-left: 31px;
      padding-right: 31px;
    }
  }

  .app-popup-body {
    height: 45%;
    display: grid;
    gap: 35px;
    padding: 57px 35px;

    .app-popup-image {
      text-align: center;
    }

    .app-popup-body-text {
      text-align: center;
    }

    form input[type=text], form input[type=password] {
      max-width: 527px;
    }
  }

  .app-popup-actions {
    width: 100%;
    padding: 60px 0 29px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.app-pt-30 {
      padding-top: 30px;
    }

    &.app-no-top-padding {
      padding-top: 0;
    }
  }

  .app-popup-buttons {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    height: 46px;
    flex-shrink: 0;
    padding-bottom: 35px;

    .app-popup-left-button {
      position: absolute;
      left: 0;
      border: none;
      border-radius: 0 9px 9px 0;
      background-color: #e4e4e4;
      color: $primary-black-color;
      opacity: .9;
    }

    .app-popup-right-button {
      position: absolute;
      right: 0;
      border: none;
      border-radius: 9px 0 0 9px;

      &[disabled] {
        background-color: #b1b1b0;
        opacity: 1;
      }
    }
  }
}

.app-select {
  width: 100%;
  height: fit-content;
  position: relative;

  &[aria-disabled="true"],
  &[aria-disabled],
  *[aria-disabled="true"],
  *[aria-disabled] {
    opacity: 0.5;
  }

  &[aria-disabled="false"],
  *[aria-disabled="false"] {
    opacity: 1;
  }

  .app-select-div {
    cursor: pointer;
    border-radius: 12px;
    font-size: 20px;
    border: none;
    padding: 15px 20px;
    background-color: #ffffff;
    box-sizing: border-box;
    width: 100%;
    font: normal normal normal 20px/20px "myriad-pro";
    color: $primary-black-color;
    min-height: 44px;
    height: fit-content;
    display: flex;
    align-items: center;
    
    &.app-select-div-placeholder {
      color: hexToRGBA($primary-black-color, 0.3);
    }
  }

  .app-select-outline-search {
    .app-form-control-search-icon {
      left: 19px !important;
      top: 14px;
    }

    svg {
      opacity: 0.43;
      width: 19px;
      height: 19px;
    }

    .app-form-control-input {
      margin-left: 0 !important;
    }

    input {
      box-shadow: inset 0px 3px 4px #00000029;
      border: 1px solid #3C3C3B !important;
      border-radius: 12px;
      margin-top: 1px;

      &::placeholder {
        opacity: 0.13;
        text-transform: none !important;
      }
    }
  }


  .app-select-trigger-container {
    display: inline-block;
    cursor: pointer;
  }

  input[type=text]:read-only,
  input[type=password]:disabled {
    cursor: pointer;
  }

  &.app-select-dialling-code {
    width: 196px;
  }

  .app-select-dropdown-icon {
    position: absolute;
    top: 45%;
    right: 20px;
    cursor: pointer;
  }

  &.app-select-not-in-view {
    .app-select-items {
      top: -203px;
    }
  }

  .app-select-items {
    border-radius: 12px;
		box-sizing: border-box;
    z-index: 1;
    background-color: $primary-white-color;
    width: 100%;
    display: inline-block;
    @include formControlFont;
    color: $primary-black-color;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 46px;
    box-shadow: 0 1px 1px $border-color;
    border: 1px solid $primary-gray-color;
    
    .app-select-items-scroll-area {
      overflow-y: auto;
      max-height: 200px;

			&.app-select-items-scroll-area-outline-enabled {
				max-height: 146px;
			}
    }


    .app-select-item {
      border-radius: 12px;
      @include formControlFont;
      color: $primary-black-color;
      text-decoration: none;
      box-sizing: border-box;
      cursor: pointer;
      padding: 8px 12px;

      &[aria-selected]:not([aria-selected="true"]):hover {
        @include link-hover;
      }

      &[aria-selected="true"] {
        @include link-active;
      }
    }
  }

  &.app-popup-menu {
    .app-select-items {
      width: fit-content;
      white-space: nowrap;

      .app-select-item {
        padding: 12px 20px;
      }
    }
  }
}

.app-help-video-items {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 37px;
  margin-right: 99px;

  .app-help-video-item {
    iframe {
      width: 100%;
      border: $primary-green-color 2px solid;
      border-radius: 26px;
      pointer-events: none;
      cursor: pointer;
    }

    &:nth-child(-1n + 3) {
      grid-column: span 4;
    }

    &:nth-last-child(2) {
      grid-row-start: 2;
      grid-column: 3 / span 4;
    }

    &:nth-last-child(1) {
      grid-row-start: 2;
      grid-column: 7 / span 4;
    }

    .app-help-video-item-title {
      text-decoration: underline;
      font: normal normal normal 14px/21px 'myriad-pro';
      color: #2E6D96;
      cursor: pointer;
      margin-top: 14px;
      text-align: center;
    }
  }
}

.app-tooltip {
  position: relative;
  cursor: pointer;

  .app-tooltip-body {
    z-index: 1;
    background: $primary-white-color;
    padding: 17px 17px 0;
    border-radius: 17px;
    top: 0;
    left: 37px;
    position: absolute;
    box-shadow: 0 1px 1px #00000029;
  }
}

.app-whitespace-no-wrap {
  white-space: nowrap;
}

.app-help-popup-content {
  height: 746px;
  width: 1227px;
  max-width: none;
  color: $primary-black-color;

  .app-single-video-container {
    width: 975px;
  }

  .app-single-video {
    width: 975px;
    height: 492px;
  }
}

.app-form-control {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  &.app-w-unset {
    width: unset;
  }

  &.app-form-control-search {
    .app-form-control-input {
      position: relative;
      margin-left: 55px;

      &.app-no-ml {
        margin-left: 0;
      }

      .app-form-control-search-icon {
        position: absolute;
        left: 15px;
      }

      input {
        text-indent: 30px;
      }
    }

    .app-transfer-site-list-left {
      display: flex;
      flex: 1 1;
      max-width: 445px;
    }
  }

  .app-form-error {
    color: $secondary-red-color;
    padding-left: 22px;
    position: absolute;
    bottom: -18px;
    font: normal normal normal 16px/16px 'myriad-pro';

    &.app-form-error-with-prefix {
      padding-left: 78px;
    }

  }

  .app-form-control-label {
    display: flex;
    margin-left: 19px;
    font: normal normal 600 20px/28px 'myriad-pro';
    color: $primary-black-color;
    
    &.app-form-control-label-with-prefix {
      margin-left: 62px;
    }

    &.app-form-contol-label-with-prefix-at-text-start {
      margin-left: 74px;
    }
  }

  .app-form-control-input-link {
    font: normal normal normal 16px/22px 'myriad-pro';
    text-decoration: underline;
    color: $primary-green-color;
    margin-top: 15px;
    cursor: pointer;

    &.app-form-control-input-link-with-prefix {
      padding-left: 69px;
    }
  }

  .app-form-control-input {
    display: flex;
    align-items: center;
    gap: 30px;
    flex: 1;

    &.app-gap-0 {
      gap: 0;
    }

    &.app-no-growth {
      flex: 0 0 auto;
    }

    .app-form-control-prefix {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    .app-post-input-content {
      position: absolute;
      right: -40px;
      top: 10px;
    }

    .app-form-control-postfix {
      position: absolute;
      right: 15px;
      cursor: pointer;
      height: fit-content;

      &.app-form-control-postfix-eye {
        top: 10px;
      }
    }

    .app-form-input-postfix-switch {
      position: absolute;
      right: 12px;
      top: 8px;
    }

    .app-form-input-postfix-button {
      position: absolute;
      right: 0;
      width: 182px;
      min-width: 182px;
    }

    input[type=text], input[type=password] {
      border-radius: 12px;
      border: none;
      &.app-form-input-outlined {
        border: 0.5px solid #3C3C3B;
      }
      padding: 15px 20px;
      background-color: $primary-white-color;
      box-sizing: border-box;
      width: 100%;
      @include formControlFont;
      color: $primary-black-color;
      height: 44px;

      &:disabled {
        background-color: $primary-gray-color2;
        color: hexToRGBA($primary-black-color, 0.6);
      }

      &::placeholder {
        @include formControlFont;
        color: $primary-black-color;
        opacity: 0.13;
      }

      &:focus {
        outline: none;
        @include formControlFocused;
      }

      &.app-form-input-outlined {
        box-shadow: 0 1px 1px $border-color;
        border: 0.5px solid $primary-black-color;

        &::placeholder {
          opacity: 0.18;
        }

        &:focus {
          outline: none;
          @include formControlFocused;
        }
      }

      &::-ms-clear, &::-ms-reveal {
        display: none !important;
      }
    }
  }

  *[aria-invalid="true"],
  &[aria-invalid="true"] {
    .app-form-select .app__control.app__control--is-focused {
      border: none;
    }

    .app-form-select,
    input[type=text],
    input[type=text]:focus,
    input[type=text].app-form-input-outlined,
    input[type=text].app-form-input-outlined:focus,
    input[type=password],
    input[type=password]:focus,
    input[type=password].app-form-input-outlined,
    input[type=password].app-form-input-outlined:focus {
      border: 0.5px solid $secondary-red-color;
    }
  }

  &[aria-disabled="true"],
  &[aria-disabled],
  *[aria-disabled="true"],
  *[aria-disabled] {
    opacity: 0.5;
  }

  &[aria-disabled="false"],
  *[aria-disabled="false"] {
    opacity: 1;
  }
}

.app-welcome {
  .app-signup-top-left-logo {
    position: absolute;
    top: 20px;
    left: 16px;
    z-index: 9999;

    @media screen and (max-width: 1002px) {
      & {
        display: none;
      }
    }
  }

  .app-welcome-logo {
    width: 462px;
    height: 113px;
  }

  .app-welcome-to {
    font: normal normal normal 30px/43px 'myriad-pro';
    color: $primary-black-color;
  }

  .app-primary-button,
  .app-secondary-button {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 373px;
    font: normal normal 600 20px 'myriad-pro';
    text-transform: none;
    text-align: center;
    height: 50px;
  }

  @media only screen and (max-width: 1002px) {
    align-items: center;
    justify-content: center;
    height: 100vh;

    .app-welcome-logo {
      width: 267px;
      height: auto;
    }

    & > img {
      display: none;
    }

  }

  @media only screen and (max-width: 382px) {
    padding: 0 17px;

    & > div {
      width: 100%;

      .app-select,
      .app-primary-button,
      .app-secondary-button {
        width: calc(100% - 34px);
      }
    }
  }
}

.app-bg-banner {
  background: #CCE5BA;
}

.app-status-banner {
  padding: 31px 48px;
  border-radius: 12px;
}

.app-region-tabs {
  border-bottom: 3px white solid;

  .app-region-tab-button {
    border: none;
    cursor: pointer;
    font-size: 20px;
    line-height: 28px;
    min-width: 130px;
    text-align: left;
    padding: 0 0 0 21px;

    &:hover {
      opacity: 0.5;
    }
    
    &.app-region-tab-button-active {
      font-weight: bold;
      border-bottom: 3px solid $primary-green-color;
      margin-bottom: -3px;
    }
  }
}

.app-text-secondary-red-color {
  color: $required-mark-color;
}

.app-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

